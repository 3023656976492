<template>
	<div v-if="positions && positions.length">
		<h3 class="text-center mb-4">{{title}}</h3>
		<div class="space-y-1">
			
			<div 
				v-for="(position, index) in positions.slice(0, settings?.positions || 5 )"
				:key="'key_'+position.user.username+'_total_'+type"
				class="flex items-center space-x-4 bg-beige-dark p-2 overflow-hidden ">
				
				<div class="flex-none w-8 text-2xl font-bold text-center opacity-75">
					{{ index+1 }}
				</div>
				
				<div class="flex-none w-12 h-12 rounded-full overflow-hidden">
					<img 
						v-if="position.user.picture"
						:src="fileKeyToUrl( position.user.picture )"
						alt="Logo"
						class="object-cover w-full h-full"
					/>
					<i 
						v-else
						class="fa fa-user fa-user-tie bg-white w-full h-full items-center justify-center flex text-xl" 
					/>
				</div>
				
				<div class="flex-grow truncate text-gray-darkest">
					{{position.user.given_name || position.user.given_name ? position.user.given_name + ' ' + position.user.family_name : position.user.email}}
					<!-- <pre>{{ position }}</pre> -->
				</div>
				
				<!-- <div class="flex-none text-center pr-6">
					Kudos list
				</div> -->
				
				<div class="flex-none text-center pr-6">
					<!-- <div 
						v-if="kudosGiven"
						class="rounded-full bg-green text-white w-8 h-8 flex items-center justify-center">
						
						<i class="fas fa-thumbs-up leading-none mb-1" />
					</div>
					<div 
						v-else
						class="rounded-full bg-white w-8 h-8 flex items-center justify-center">
						
						<i class="fas fa-thumbs-up leading-none mb-1" />
					</div> -->
				</div>
				
				<div class="flex-none text-right pr-6">
					<div 
						v-if="type == 'products'"
						class="">
						
						kr {{currencyFormat(position.sum)}}
						<!-- <div>{{position.qty}} enheter</div> -->
					</div>
					<div 
						v-if="type == 'tasks'"
						class="">
						
						{{ position.qty }} oppgaver
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		props: {
			settings: {
				type: Object,
				required: true,
			},
			positions: {
				type: Array,
				required: true,
			},
			type: {
				type: String,
				required: true,
				default: 'products', 
			},
			users: {
				type: Array,
				required: true,
			},
			title: {
				type: String,
				required: true,
			},
		},
		
		computed:{
			// 
		},
		
		data(){
			return {
				// 
			}
		},
		
		methods: {
			// 
		}
	}
</script>
